import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import { Flex } from 'CHAMELEON_REACT_HUB';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DocsIllustration = makeShortcode("DocsIllustration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "overview-of-tiers",
      "style": {
        "position": "relative"
      }
    }}>{`Overview of tiers`}</h2>
    <p>{`There are three tiers within Chameleon.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tier 0`}</strong>{`: The base tier contains the foundations: values that make up the
basis for each brand.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tier 1`}</strong>{`: A platform-specific translation of the values in the foundations
to tokens.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tier 2`}</strong>{`: Implementation of the foundations and tokens in tier 0 and tier 1
in the form of base building blocks (e.g. button, paragraph, ...).`}</li>
      <li parentName="ul"><strong parentName="li">{`Tier 3`}</strong>{`: Team-specific patterns and components that combine multiple
elements from tier 0 and tier 1.`}</li>
    </ul>
    <Flex justifyContent="center" mdxType="Flex">
  <DocsIllustration name="TierFull" mdxType="DocsIllustration" />
    </Flex>
    <h3 {...{
      "id": "tier-0-foundations",
      "style": {
        "position": "relative"
      }
    }}>{`Tier 0: Foundations`}</h3>
    <p>{`Foundations can be seen as the DNA for a specific brand and form a single source
of truth. These include, but are not limited to, color, typography, spacing,
etc.`}</p>
    <p>{`As these foundations are generally applicable, they form the heart of Chameleon
and are thus tier 0.`}</p>
    <Flex justifyContent="center" mdxType="Flex">
  <DocsIllustration name="Foundations" mdxType="DocsIllustration" />
    </Flex>
    <h3 {...{
      "id": "tier-1-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tier 1: Tokens`}</h3>
    <p>{`Since foundations are more or less abstract concepts that are platform-agnostic,
they need a specific translation to be used in code. This is where tokens come
into play. Tokens are an implementation of these agreed-upon truths for a
specific platform and bridge the gap between design and development.`}</p>
    <Flex justifyContent="center" mdxType="Flex">
  <DocsIllustration name="Tokens" mdxType="DocsIllustration" />
    </Flex>
    <h3 {...{
      "id": "tier-2-building-blocks",
      "style": {
        "position": "relative"
      }
    }}>{`Tier 2: Building blocks`}</h3>
    <p>{`Using the tokens from tier 1, Chameleon provides an ever-evolving set of base
building blocks. These building blocks are mostly low-level components that can
be combined to create compelling user-facing interfaces.`}</p>
    <p>{`These building blocks are platform-tailored, as they are ready-to-use in
development projects. As such, there will be multiple different implementations,
i.e. an implementation for web, an implementation for Android, etc.`}</p>
    <Flex justifyContent="center" mdxType="Flex">
  <DocsIllustration name="Components" mdxType="DocsIllustration" />
    </Flex>
    <p>{`Tier 0, tier 1 and tier 2 are actively managed and maintained by the Chameleon
team. What this means is that we ensure correct color contrasts in the
foundations, fully functional and correctly formatted fonts and components that
are both functionally and visually tested and pass our accessibility checklist.`}</p>
    <h3 {...{
      "id": "tier-3-team-specific-components",
      "style": {
        "position": "relative"
      }
    }}>{`Tier 3: Team-specific components`}</h3>
    <p>{`The true power of our design system tiers lies in tier 3. With tier 3, we want
to encourage teams to set up their internal design system. E.g., a subscription
offer that is built out of several of the tier 2 building blocks is a perfect
candidate to belong to a team's design system. This enables the team to reuse
this component across all of their projects.`}</p>
    <p>{`However, the use case for a team's design system is not limited to internal team
projects. Teams are encouraged to share their patterns with other teams, to
assure an even higher degree of unification.`}</p>
    <Flex justifyContent="center" mdxType="Flex">
  <DocsIllustration name="TeamComponents" mdxType="DocsIllustration" />
    </Flex>
    <p>{`When compared to tier 2 components and patterns, the Chameleon team does not
guarantee the quality of these components. However, we are always willing to aid
teams in ensuring the quality of their shared components.`}</p>
    <h2 {...{
      "id": "promotion-between-tiers",
      "style": {
        "position": "relative"
      }
    }}>{`Promotion between tiers`}</h2>
    <p>{`Another advantage of the multi-tier system is that it enables the Chameleon team
to promote patterns and components between tiers.`}</p>
    <p>{`If a certain pattern turns out to be highly-used across teams, it is our job to
promote this pattern to tier 2. That way, we can fully polish the overall
quality of the component and make it more generally available.`}</p>
    <p>{`A clear example of this promotion could be an article teaser. After all,
Mediahuis will always be a media company, so articles lie at the heart of the
organization. While the initial implementation of this pattern can belong to a
certain team, after time this could be promoted to tier 2 to become a
first-class citizen of Chameleon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      